export const ACCESS_RIGHTS = [
  "EDUCATE_ANALYTICAL_GLOBAL_R",
  "EDUCATE_ANALYTICAL_LOCAL_R",
  "EDUCATE_GLOBAL_COURSES_RW",
  "EDUCATE_GLOBAL_MEMBER_RW",
  "EDUCATE_GLOBAL_COURSES_R",
  "EDUCATE_LOCAL_ADMIN_RW",
  "EDUCATE_LOCAL_COURSES_RW",
  "EDUCATE_LOCAL_MEMBER_RW",
  "EDUCATE_LOCAL_COURSES_R",
  "EDUCATE_PRACTITIONER_RW",
  "EDUCATE_PRACTITIONER_R",
  "CONNECT_APP_RW",
  "EDUCATE_VIEW_PRACTITIONER_RW",
  "EDUCATE_VIEW_LOCAL_ADMIN_RW",
  "EDUCATE_VIEW_GLOBAL_ADMIN_RW",
  "EDUCATE_VIEW_SUPER_ADMIN_RW",
  "EDUCATE_GLOBAL_ADMIN_RW",
  "EDUCATE_SUPER_ADMIN_RW",
  "EDUCATE_VIEW_SUPER_ADMIN",
  "EDUCATE_VIEW_GLOBAL_ADMIN",
  "EDUCATE_VIEW_LOCAL_ADMIN",
  "EDUCATE_VIEW_PRACTITIONER",
  "EDUCATE_LEAD_PHYSICIAN_RW",
  "EDUCATE_SUPER_ADMIN",
  "CONNECT_SUPER_ADMIN",
  "ANALYTICS",
  "EDUCATE_SUPER_ADMIN",
  "CONNECT_SUPER_ADMIN",
  "CARE_ADMIN_USER_RW",
] as const;

export type AccessRight = (typeof ACCESS_RIGHTS)[number];
